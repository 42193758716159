import React from "react";
import SEO from "@src/components/SEO";
import Logo from "@src/assets/images/configure-app/logo.svg";
import { Box, Flex } from "@src/components/Boxes";
import { calcCodeAttribution } from "@src/utils/attribution";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";

const PCODE = ({ id }: { id: string }) => {
  useRedirectHandler(() => {
    const redirectLink = `https://home.classdojo.com/#/signup?invite=${id}&${calcCodeAttribution().toString()}`;
    return redirectLink;
  });

  return (
    <>
      <SEO noindex={true} />
      <Flex justifyContent="center" alignItems="center" width="100%" height="calc(100vh - 88px)">
        <Box width="100px" minWidth="100px">
          <img src={Logo} alt="" />
        </Box>
      </Flex>
    </>
  );
};
export default PCODE;
